import { template as template_d27b42a836d0462aa0e5fd2ba2b6e57a } from "@ember/template-compiler";
const WelcomeHeader = template_d27b42a836d0462aa0e5fd2ba2b6e57a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
