import { template as template_9d3a20d26f7b44dd869216f122bfc50d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_9d3a20d26f7b44dd869216f122bfc50d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
