import { template as template_1b78fb00240d4dde96e513106df1a98e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_1b78fb00240d4dde96e513106df1a98e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
