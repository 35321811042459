import { template as template_099ef9685b8f4bd5baef7a0f8ac58586 } from "@ember/template-compiler";
const FKLabel = template_099ef9685b8f4bd5baef7a0f8ac58586(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
